.circle-label-hover {
    background-color: white;
    border-radius: 50%;
    border: 3px solid black;
    padding: 5px;
    text-align: center;
    font-weight: bold;
  }

  .text-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  